<template>
	<div class="full-height">
		<h6>{{ program.name }}</h6>
		<div
			class="pa-10 bg-white width-50"
		>
			<table class="table   td-left">
				<col width="120px">
				<tbody>
					<tr>
						<th>총판</th>
						<td>
							<select
								v-model="item.viewGroup"
								class="pa-10 "
							>
								<option
									v-for="(branch, index) in items_branch"
									:key="'branch_' + index"
									:value="branch.code"
								>{{ branch.name }}</option>
							</select>
						</td>
					</tr>
					<tr>
						<th>제목</th>
						<td><input v-model="item.title" class="input-box pa-10" placeholder="제목을 입력하세요" maxlength="50"/></td>
					</tr>
					<tr>
						<th>내용</th>
						<td>
							<editor
								v-model="item.content"
								height="350px"
								initialEditType="markdown"
								ref="content"
								class="text-left "
							/>
						</td>
					</tr>
					<tr>
						<th>예약 등록</th>
						<td>
							<button
								class="pa-5-10 "
								:class="item.isReserved == '0' ? 'bg-identify' : 'bg-identify-outline'"
								@click="item.isReserved = '0'"
							>즉시 공지</button>
							<button
								class="pa-5-10 mr-10"
								:class="item.isReserved == '1' ? 'bg-identify' : 'bg-identify-outline'"
								@click="item.isReserved = '1'"
							>예약 공지</button>

							<template
								v-if="item.isReserved == '1' "
							>
								<span class="position-relative">
									<input v-model="item.date" class="input-box-inline text-center  mr-10" placeholder="예약일" readonly @click="is_data_pick = !is_data_pick" />
									<v-date-picker
										v-if="is_data_pick"
										v-model="item.date"
										no-title
										scrollable
										class="position-absolute"
										style="top: 35px; right: 70px"
										@change="is_data_pick = false"
									></v-date-picker>
								</span>
								<select
									v-model="item.hour"
									class="input-box-inline  "
								>
									<option value="">시간</option>
									<option
										v-for="time in 24"
										:key="'time_' + time"
										:value="time - 1"
									>{{ ('0' + (time - 1)).slice(-2) }}</option>
								</select> :

								<select
									v-model="item.min"
									class="input-box-inline "
								>
									<option value="">분</option>
									<option value="0">00</option>
									<option value="30">30</option>
								</select>
							</template>
						</td>
					</tr>
					<tr>
						<th>상단 고정</th>
						<td>
							<button
								class="pa-5-10 "
								:class="item.fix == '1' ? 'bg-identify' : 'bg-identify-outline'"
								@click="item.fix = '1'"
							>고정</button>
							<button
								class="pa-5-10 mr-10"
								:class="item.fix == '0' ? 'bg-identify' : 'bg-identify-outline'"
								@click="item.fix = '0'"
							>미고정</button>

							<label>
								상단 고정 선택 시 최근 등록 순으로 정렬됩니다.
							</label>
						</td>
					</tr>
				</tbody>
			</table>

			<div
				class="mt-30 justify-center"
			>
				<button
					class="bg-identify pa-10-20 mr-10"
					@click="save"
				>확인</button>
				<button
					class="bg-ddd pa-10-20"
					@click="isCancel"
				>목록</button>
			</div>
		</div>

		<Modal
			:is_modal="is_modal"
			:option="modal_option"
			:top="true"
			:bottom="true"

			title="등록 취소"
			content="내용을 저장하지 않고 목록으로 돌아가시겠습니까?"
			width="400px"
			content_class="ptb-30"

			@close="clear"
			@click="toList"
			@cancel="clear"
		></Modal>
	</div>
</template>

<script>

import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor';
import Modal from "@/components/Modal";

export default {
	name: 'NoticeItem'
	,props: ['Axios', 'user', 'codes', 'rules', 'date']
	,components: {Modal, Editor}
	,data: function(){
		return {
			program: {
				name: '공지사항 등록'
				, top: true
				, title: true
				, bottom: false
			}
			,item: {
				hour: '0'
				,min: '0'
				,isReserved: 0
				,fix: 0
				,viewGroup: '0'
				,date: this.date.getToday('-')
			}
			,is_data_pick: false
			,items_branch: []
			,is_modal: false
			,modal_option: {

			}
		}
	}
	,computed: {

	}
	, methods: {
		save: async function(){
			try{
				this.$emit('onLoading')

				let content = this.$refs.content.invoke("getMarkdown")

				this.item.content = content
				this.$set(this.item, 'reservedTime', this.item.date.replaceAll('-', '') + ('0' + this.item.hour).slice(-2) + ('0' + this.item.min).slice(-2))

				let result = await this.Axios({
					method: 'post'
					,url: 'notice'
					,data: this.item
				})
				if(result.success){
					this.$emit('setNotify', { type: 'success', message: result.message})
					this.$router.back()
				}else{
					this.$emit('setNotify', { type: 'error', message: result.message})
					this.$emit('setNotify', { type: 'error', message: result.error[0].message})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,getBranch: async function(){
			try{
				this.$emit('onLoading')
				const result = await this.Axios({
					method: 'get'
					,url: 'notice/getViewGroup'
					,data: {
						branchWriter: '1'
					}
				})
				if(result.success){
					this.items_branch = result.data
				}else{
					this.$emit('setNotify', { type: 'error', message: this.$language.common.error})
				}
			}catch(e){
				console.log(e)
			}finally {
				this.$emit('offLoading')
			}
		}
		,isCancel: function(){
			this.is_modal = true
		}
		,close: function(){
			this.is_modal = false
		}
		,clear: function(){
			this.is_modal = false
		}
		,toList: function(){
			//this.$emit('push', { name: 'NoticeList', not_query: true})
			this.$router.back()
		}
	}
	, created() {
		this.$emit('onLoad', this.program)
		this.getBranch()
	}
}
</script>

<style>
	.toastui-editor-defaultUI-toolbar button {
		margin: 7px 0px;
	}
</style>
